<template>
  <b-overlay
    :show="data === null || disabledCache"
    rounded="sm"
  >
    <b-form
      v-if="data"
    >
      <b-row>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-group
            :label="$t('form.maintenance_mode.label')"
            label-for="maintenance_mode"
            :state="errors && errors.maintenance_mode ? false : null"
          >
            <v-select
              id="maintenance_mode"
              v-model="data.maintenance_mode"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="maintenance_mode"
            />
            <b-form-invalid-feedback v-if="errors && errors.maintenance_mode">
              {{ errors.maintenance_mode[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Скинути системний кеш на сайті"
            label-for="cache"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="disabledCache"
              @click="clearCache"
            >
              Скинути
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.localization.language.title')"
            label-for="language"
          >
            <v-select
              v-model="data.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :reduce="val => val.value"
              :clearable="false"
              input-id="language"
            />
            <b-form-invalid-feedback v-if="errors && errors.language">
              {{ errors.language[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.localization.admin_language.title')"
            label-for="admin_language"
          >
            <v-select
              v-model="data.admin_language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :reduce="val => val.value"
              :clearable="false"
              input-id="admin_language"
            />
            <b-form-invalid-feedback v-if="errors && errors.admin_language">
              {{ errors.admin_language[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_insta.label')"
            label-for="social_insta"
          >
            <b-form-input
              id="social_insta"
              v-model="data.social_insta"
              :state="errors && errors.social_insta ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_insta">
              {{ errors.social_insta[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_fb.label')"
            label-for="social_fb"
          >
            <b-form-input
              id="social_fb"
              v-model="data.social_fb"
              :state="errors && errors.social_fb ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_fb">
              {{ errors.social_fb[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_yt.label')"
            label-for="social_yt"
          >
            <b-form-input
              id="social_yt"
              v-model="data.social_yt"
              :state="errors && errors.social_yt ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_yt">
              {{ errors.social_yt[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_viber.label')"
            label-for="social_viber"
          >
            <b-form-input
              id="social_viber"
              v-model="data.social_viber"
              :state="errors && errors.social_viber ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_viber">
              {{ errors.social_viber[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_tg.label')"
            label-for="social_tg"
          >
            <b-form-input
              id="social_tg"
              v-model="data.social_tg"
              :state="errors && errors.social_tg ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_tg">
              {{ errors.social_tg[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.social_tiktok.label')"
            label-for="social_tiktok"
          >
            <b-form-input
              id="social_tiktok"
              v-model="data.social_tiktok"
              :state="errors && errors.social_tiktok ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.social_tiktok">
              {{ errors.social_tiktok[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.email.label')"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="data.email"
              :state="errors && errors.email ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.email">
              {{ errors.email[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('form.phone.label')"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="data.phone"
              :state="errors && errors.phone ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.phone">
              {{ errors.phone[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>

      <b-tabs>
        <b-tab
          v-for="lang in languages"
          :key="lang.value"
        >
          <template #title>
            <b-img
              :src="require('@/assets/images/flags/' + lang.value + '.svg')"
              height="16"
              width="16"
              class="mr-1"
            />
            <span class="d-none d-sm-inline">{{ lang.label }}</span>
          </template>
          <b-row>
            <b-col
              cols="24"
              md="6"
            >
              <b-form-group
                :label="$t('form.global_header.phone')"
                :label-for="'header.' + lang.value + '.phone'"
              >
                <b-form-input
                  :id="'header.' + lang.value + '.phone'"
                  v-model="data.header[lang.value].phone"
                  :state="errors && errors.header ? false : null"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="24"
              md="6"
            >
              <b-form-group
                :label="$t('form.global_header.text')"
                :label-for="'header.' + lang.value + '.text'"
              >
                <b-form-input
                  :id="'header.' + lang.value + '.text'"
                  v-model="data.header[lang.value].text"
                  :state="errors && errors.header ? false : null"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="'Header info line'"
                :label-for="'header.' + lang.value + '.info'"
              >
                <b-form-input
                  :id="'header.' + lang.value + '.info'"
                  v-model="data.header[lang.value].info"
                  :state="errors && errors.header ? false : null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <br>
        </b-tab>
      </b-tabs>

      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import StatusMixin from '@/mixins/StatusMixin'
import _ from 'lodash'
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin, StatusMixin],
  data() {
    return {
      languages: [],
      data: null,
      language: null,
      disabledCache: false,
    }
  },
  async beforeCreate() {
    await this.$http('/api/languages')
      .then(response => {
        this.languages = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })

    await this.$http('/api/admin/settings', { params: { group: 'general' } }).then(response => {
      this.data = this.transformData(response.data)

      /* eslint-disable camelcase */
      this.language = response.data.admin_language
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/admin/settings/general', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')
          await localStorage.setItem('localization', JSON.stringify({
            admin_language: this.data.admin_language,
            language: this.data.language.value,
          }))
          await this.changeLanguage()
          if (this.language !== this.data.admin_language) {
            window.location.reload()
          }

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    clearCache() {
      this.confirm(() => {
        this.disabledCache = true
        setTimeout(() => {
          this.disabledCache = false
        }, 60000)
        this.$http.post('/api/admin/cache')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: 'Запустити скидання?',
        text: 'Скидання триває 5-10 хв у фоновому режимі (декілька разів нажимати не треба) і оновлює скрипти та переклади. У цей час не проводити дії з адмінкою через ризик втрати вашої роботи. Рекомендовано скидати кеш не частіше 1 разу на добу. Бажано скидати у період найменшої активності користувачів.',
      })
    },
    changeLanguage() {
      this.$i18n.locale = this.data.admin_language
      this.$i18n.fallbackLocale = this.data.language
    },
    transformData(item) {
      const data = {
        maintenance_mode: item.maintenance_mode,
        social_fb: item.social_fb,
        social_insta: item.social_insta,
        social_viber: item.social_viber,
        social_yt: item.social_yt,
        social_tg: item.social_tg,
        social_tiktok: item.social_tiktok,
        email: item.email,
        phone: item.phone,
        language: item.language,
        admin_language: item.admin_language,
        header: item.header,
        footer: item.footer,
      }

      _.each(this.languages, language => {
        if (!data.header[language.value]) {
          data.header[language.value] = {
            phone: '123123',
            text: '123123',
            info: '123123',
          }
        }
        if (!data.header[language.value]) {
          data.footer[language.value] = {
            phone: '123123',
            text: '123123',
            phone2: '43223',
            text2: '123123',
          }
        }
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
